import { computed } from 'vue';
const cacheMap = new WeakMap()

export const useVModel = ( props,propsName ,emit) =>{
  return computed({
    get(){
      if(cacheMap.has(props[propsName])){
        return cacheMap.get(props[propsName])
      }
      const proxy = new Proxy(props[propsName],{
        get(target,key){
          return Reflect.get(target,key)
        },
        set(target,key,val){
            emit(`update:${propsName}`,{
              ...target,
              [key] :val
            })
            return true
        }
      })
      cacheMap.set(props[propsName],proxy)
      return proxy
    },
    set(val){
      emit(`update:${propsName}`,val)
    }
  })
}