<template>
  <div
      class="zh-selectByAbc cur_p"
      v-bind="$attrs"
      :class="[checkModal?'active':'']"
      @mouseenter="hoverIn"
      @mouseleave="hoverOut"
      @click.stop="handleShowCheckModal"
      v-loading="loading"
  >
    <template v-if="selectModal.name">
      <div class="zh-selectByAbc_tag">
        {{ selectModal.name }}
      </div>
    </template>
    <div v-else class="placeText">{{ titleInfo.placeText }}</div>
    <div class="arraw">
      <el-icon v-if="!selectModal.name">
        <ArrowDown/>
      </el-icon>
      <div class="icon-o" v-if="selectModal.name && showDel" @click.stop="delTags">
        <el-icon>
          <Close/>
        </el-icon>
      </div>
    </div>
    <Modal :queryText="queryText" :title="title" @on-change="change" @on-click-ok="handleOk" @on-submit="searchForm" v-model="checkModal"
           :list="letterList" :buyBack="buyBack"
           :saleBack="saleBack" :update="update" :add="add" :type="type"/>
  </div>
</template>

<script setup>
import {computed, onMounted, provide, ref, toRefs} from 'vue';
import {typeConfig} from './config';
import Modal from './components/modal.vue';
import message from '@/utils/tool/message';

const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  add: {
    type: Boolean,
    default: false
  },
  update: {
    type: Boolean,
    default: false,
  },
  saleBack: {
    type: Boolean,
    default: false,
  },
  buyBack: {
    type: Boolean,
    default: false,
  },
  stockId: {
    type: [String, Number],
    default: ''
  },
  stockName: {
    type: String,
    default: ''
  },
  queryText:{
    type: String,
    default: ''
  }
})
const {modelValue,queryText, type, add, update, saleBack, buyBack, stockId, stockName} = toRefs(props)
const checkModal = ref(false)
//标题信息
const titleInfo = typeConfig[type.value]
const letterList = ref([])
const selectModal = ref({})
const showDel = ref(false)
const loading = ref(false)
const title = computed(() => {
  return titleInfo.title + (stockName.value ? `(${stockName.value})` : '')
})

const emit = defineEmits(['update:modelValue', 'onChange'])
//选中模型计算器
const activeModal = () => {
  if (!modelValue.value) {
    selectModal.value = {}
    return
  }
  selectModal.value = {}
  letterList.value.forEach(item => {
    item.list.forEach(item2 => {
      if (item2.id === modelValue.value) {
        selectModal.value = item2
      }
    })
  })
}

//搜索标点

//鼠标移入
const hoverIn = () => {
  showDel.value = true
}
//鼠标移出
const hoverOut = () => {
  showDel.value = false
}
//删除标签
const delTags = () => {
  selectModal.value = {}
  emit('update:modelValue', '')
  emit('onChange')
}

//点击打开弹窗
const handleShowCheckModal = async () => {
  await getList()
  activeModal()
  checkModal.value = true
}

const getList = async (form = {}) => {
  const api = titleInfo.getList
  const key = titleInfo.key
  loading.value = true
  const res = await api({
    [key]: form.name || "",
    saleBack: saleBack.value,
    buyBack: buyBack.value,
    stockId: stockId.value
  })
  loading.value = false
  if (res.code !== 0) {
    message.error(res.msg)
    return
  }
  letterList.value = setList(res.data.list)
}

const searchForm = (form) => {
  //点击搜索
  getList(form.value)
}

const handleOk = ()=>{
  emit('update:modelValue', selectModal.value.id || '')
  emit('onChange', selectModal.value)
}


//设置列表
function setList(list = []) {
  const arr = []
  const key = titleInfo.key
  const id = titleInfo.delKey
  list.forEach(item => {
    const arr2 = []
    if (item.list?.length) {
      item.list.forEach(item2 => {
        arr2.push({
          name: item2[key],
          id: item2[id]
        })
      })
    }
    arr.push({
      prefix: item.prefix,
      list: arr2
    })
  })
  return arr
}

onMounted(async () => {
  await getList()
  activeModal()
})

const change = async () => {
  await getList()
  activeModal()
}

//注入变量
provide('titleInfo', titleInfo)
provide('selectModal', selectModal)

//暴露方法
defineExpose({handleShowCheckModal})
</script>
<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'SelectByModalNew',
})
</script>

<style lang="scss" src="./index.scss" scoped>

</style>]