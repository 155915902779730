<template>
  <div>
    <div class="selectBox cur_p">
      <div
          v-scaleClick
          class="selectBox_item cur_p"
          :class="!checkLetter ? 'active' : ''"
          @click="checkAbc()"
      >
        所有{{ titleInfo.unit }}
      </div>
      <div
          v-scaleClick
          v-for="item in list"
          :key="item"
          class="selectBox_item cur_p"
          :class="item.prefix === checkLetter ? 'active' : ''"
          @click="checkAbc(item.prefix)"
      >
        {{ item.prefix }}
      </div>
    </div>
    <el-scrollbar class="abcCheck_info" :style="{ height :`calc( ${height}px - 400px)`}" always>
      <div
          class="abcCheck_single"
          v-for="(item, index) in list"
          :key="index"
      >
        <div
            v-if="
              item.list.length > 0 &&
              (!checkLetter || checkLetter === item.prefix.toUpperCase())
            "
        >
          <div class="abcCheck_title">{{ item.prefix }}</div>
          <div class="abcCheck_tagList" v-if="!isUpdate">
            <div
                v-scaleClick
                class="abcCheck_tags"
                :class="activeModal.id === item1.id ? 'active' : ''"
                v-for="(item1, index1) in item.list"
                :key="index1"
                @click="checkItem(item1)"
            >
              <span class="abcCheck_tags_text"> {{ item1.name }}</span>
            </div>
          </div>
          <div class="abcCheck_tagList" v-if="isUpdate">
            <div
                v-scaleClick
                class="abcCheck_tags animate__animated"
                v-for="item1 in item.list"
                :key="item1"
                @click="delItem(item1)"
            >
              <span class="abcCheck_tags_text"> {{ item1.name }}</span>
              <img src="@/assets/img/index/delete.jpg" class="del" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import {getCurrentInstance, inject, ref, toRefs} from "vue";
import {useViewPort} from '@/utils/tool/useVIewPort';
import {ElMessageBox} from "element-plus";

const {height} = useViewPort();

const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  isUpdate: {
    type: Boolean,
    default: false,
  },
  activeModal: {
    type: Object,
    default: () => ({})
  }
});

const {list, isUpdate, activeModal} = toRefs(props)

const emit = defineEmits(['update:activeModal', 'onSuccess'])
const titleInfo = inject("titleInfo");
const checkLetter = ref('')
const {proxy} = getCurrentInstance();

//选择字母
const checkAbc = (item = '') => {
  if (item === checkLetter.value) {
    checkLetter.value = ''
    return
  }
  checkLetter.value = item
};

//选中模型
const checkItem = (e) => {
  if (!isUpdate.value) {
    if (activeModal.value.id === e.id) {
      emit('update:activeModal', {})
      return
    }
    emit('update:activeModal', e)
  }
}

const delItem = (e) => {
  ElMessageBox.confirm("是否确认删除", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    const key = titleInfo.delKey;
    const api = titleInfo.deleteItem
    api({
      [key]: e.id,
    }).then(res => {
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      proxy.$message.success("移除成功");
      emit('onSuccess')
    })
  });
}

defineExpose({
  checkLetter,
})


</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "letterList",
});
</script>

<style lang="scss" scoped>
.selectBox {
  cursor: pointer;
}
</style>
