<template>
  <el-dialog
      v-bind="$attrs"
      :model-value="modelValue"
      align-center
      width="880"
      :before-close="handleCloseCheckModal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="abcCheck"
      @open="handleOpenCheckModal"
      append-to-body
  >
    <div class="zh_select_content">
      <Query v-model="formData" @on-success="addSuccess" @onSubmit="searchForm" v-model:isUpdate="isUpdate" :add="add"
             :update="update"/>
      <letter-list ref="letterRefs" :list="list" @on-success="addSuccess" v-model:active-modal="activeModal"
                   :isUpdate="isUpdate"></letter-list>
    </div>
    <template #footer>
      <el-button @click="handleCloseCheckModal" class="btn1 zh-btn"
      >关闭
      </el-button
      >
      <el-button class="main btn1" type="primary" @click="handleOkCheckModal" v-if='!isUpdate'>
        确认
      </el-button
      >
    </template>
  </el-dialog>
</template>

<script setup>
import {inject, ref, toRefs, watchEffect} from "vue";
import {typeConfig} from '../config'
import Query from './query.vue'
import LetterList from "@components/form/selectByModalNew/components/letterList.vue";

const letterRefs = ref(null)
const loading = ref(false)
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: ''
  },
  add: {
    type: Boolean,
    default: false
  },
  update: {
    type: Boolean,
    default: false,
  },
  saleBack: {
    type: Boolean,
    default: false,
  },
  buyBack: {
    type: Boolean,
    default: false,
  },
  list: {
    type: Array,
    default: () => []
  },
  queryText:{
    type: String,
    default: ''
  }
})
const {modelValue,queryText, type, add, update, saleBack, buyBack, list} = toRefs(props)
const emit = defineEmits(['update:modelValue', 'onChange', 'onSubmit','onClickOk'])
const titleInfo = typeConfig[type.value]
const selectModal = inject("selectModal");

//搜索表单
const formData = ref({
  name: ''
})
const handleOpenCheckModal = ()=>{
  formData.value.name = queryText.value || ''
}
//是否编辑
const isUpdate = ref(false);
const activeModal = ref({})
const handleCloseCheckModal = () => {
  activeModal.value = selectModal.value
  isUpdate.value = false
  setLetter()
  emit('update:modelValue', false)
}

const handleOkCheckModal = () => {
  selectModal.value = activeModal.value
  setLetter()
  emit('update:modelValue', false)
  emit('onClickOk')
}

function setLetter() {
  if (letterRefs.value?.checkLetter) {
    letterRefs.value.checkLetter = ''
  }
}

//搜索
const searchForm = () => {
  //点击搜索
  emit('onSubmit', formData)
}

//添加成功回调
const addSuccess = () => {
  emit('onChange')
}

watchEffect(() => {
  activeModal.value = selectModal.value
})

</script>

<style lang="scss" scoped>

</style>