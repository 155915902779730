<template>
  <el-button type="primary" v-bind="$attrs" @click="handleAddDia">
    <el-icon>
      <Plus/>
    </el-icon>
    <span class="text">新增</span></el-button
  >
  <el-dialog
      v-model="addModal"
      :title="titleInfo.title"
      width="500"
      align-center
      append-to-body
      class="abcCheck"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
  >
    <div class="zh-brand-add-item dp_f">
      <div class="zh-brand-item-title">
        <span class="red">*</span>{{ titleInfo.title }}：
      </div>
      <div class="zh-brand-item-input">
        <el-input
          v-elInput
            :placeholder="titleInfo.placeText"
            maxlength="20"
            v-model="formData.name"
        />
      </div>
    </div>
    <template #footer>
      <el-button class="zh-btn btn1" @click="closeAddDia">取消</el-button>
      <el-button
          class="main btn1"
          type="primary"
          @click="handleOkAdd"
          :disabled="isOk"
      >添加
      </el-button
      >
    </template>
  </el-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, inject, reactive, ref} from "vue";

const {proxy} = getCurrentInstance();
const emit = defineEmits(["onSuccess"]); // 确认添加成功的回调
const titleInfo = inject("titleInfo");
const addModal = ref(false);
const formData = reactive({
  name: "",
})

const isOk = computed(() => {
  return !formData.name;
});
const closeAddDia = () => {
  formData.name = ''
  addModal.value = false
}

const handleAddDia = () => {
  addModal.value = true;
};

const handleOkAdd = async () => {
  let key = titleInfo.key;
  let res = await titleInfo.addItem({
    [key]: formData.name,
  });
  if (res.code !== 0) {
    proxy.$message.error(res.msg);
    return;
  }
  proxy.$message.success("添加成功");
  closeAddDia()
  emit("onSuccess");
};
</script>

<style lang="scss" scoped>

</style>