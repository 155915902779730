import {brandAdd, brandDel, brandList, unitAdd, unitDel, unitList,} from "@/utils/api/product/brand.js";
import {supplierAdd, supplierDiscard, supplierQuery,} from "@/utils/api/supplier.js";
import {punterAdd, punterLIstDiscard, punterSearch} from "@/utils/api/punter.js";
import {getShelfPosition} from '@/utils/api/shelf'

export const typeConfig = {
    brand: {
        title: "产品品牌",
        unit: "品牌名称",
        placeText: "请输入品牌名称",
        getList: brandList,
        addItem: brandAdd,
        deleteItem: brandDel,
        key: 'brandName',
        delKey: 'brandId',
    },
    supplier: {
        title: "供应商",
        unit: "供应商名称",
        placeText: "请输入供应商名称",
        getList: supplierQuery,
        addItem: supplierAdd,
        deleteItem: supplierDiscard,
        key: 'supplierName',
        delKey: 'supplierId',
    },
    unit: {
        title: "产品单位",
        unit: "单位名称",
        placeText: "请输入单位名称",
        getList: unitList,
        addItem: unitAdd,
        deleteItem: unitDel,
        key: 'unitName',
        delKey: 'unitId',
    },
    punter: {
        title: "客户",
        unit: "客户名称",
        placeText: "请输入客户名称",
        getList: punterSearch,
        addItem: punterAdd,
        deletItem: punterLIstDiscard,
        key: 'punterName',
        delKey: 'punterId',
    },
    shelf: {
        title: "产品货架",
        unit: "产品货架",
        placeText: "请输入货架名称",
        getList: getShelfPosition,
        key: 'childShelfName',
        delKey: 'childShelfId',
    }
}