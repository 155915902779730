<template>
  <div class="query">
    <form class="queryBox" @submit="submit">
      <div class="text">{{ titleInfo.title }}</div>
      <el-input
          v-elInput
          :placeholder="'请输入' + titleInfo.title"
          class="w360"
          v-model="model.name"
          clearable
      ></el-input>
      <el-button type="primary" class="square" native-type="submit"
      >
        <el-icon>
          <Search/>
        </el-icon
        >
      </el-button>
    </form>
    <div class="controlBox">
      <addButton v-if="add" @on-success="addSuccess"/>
      <el-button
          v-if="update"
          type="primary"
          class="ml10 btn"
          @click="handleUpdate"
      >{{ isUpdate ? "完成" : "编辑" }}
      </el-button
      >
    </div>
  </div>
</template>

<script setup>
import {inject, toRefs} from "vue";
import {useVModel} from '@/utils/tool/useVModel'
import addButton from "./addButton.vue";

const addSuccess = () => {
  emit('onSuccess')
}
const titleInfo = inject('titleInfo')
const props = defineProps({
  add: {
    type: Boolean,
    default: false
  },
  update: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Object,
    default: () => {
    }
  },
  isUpdate: {
    type: Boolean,
    default: false
  }
})
const {add, update, isUpdate} = toRefs(props)
const emit = defineEmits(['update:modelValue', 'onSubmit', 'update:isUpdate', 'onSuccess'])
const model = useVModel(props, 'modelValue', emit)

const submit = (e) => {
  e.preventDefault()
  emit('onSubmit')
}

const handleUpdate = () => {
  emit('update:isUpdate', !isUpdate.value)
}
</script>

<style lang="scss" scoped>
:deep(.el-button) {
  font-size: $fs14;
  height: 36px;

  &.square {
    width: 36px;
  }
}
</style>